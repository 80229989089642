<template>
  <div class="menu-category">
    <h2>{{ `${category.name.zh} - ${category.name.sv}` }}</h2>
    <div class="menu-items">
      <menu-item
        v-for="(item, index) in category.items"
        :key="index"
        :item="item"
      />
    </div>
  </div>
</template>

<script>
import MenuItem from "./MenuItem.vue";
export default {
  components: { MenuItem },
  props: { category: {} }
};
</script>
