<template>
  <article class="menu">
    <p>
      <router-link :to="`/${$route.params.id}`">Tillbaka</router-link>
    </p>
    <h1 class="mb-0">特別菜單</h1>
    <h2 class="mt-0">Family Style-meny</h2>
    <p class="mb-3">
      開飯 [hoi-faan!] översätts “öppna riset!“. Det ropas när middagen är redo
      och det är dags för familjen att samlas runt bordet. Den traditionella
      familje- middagen består av olika rätter med kött, fisk, fågel och grönt -
      dvs den Kantonesiska ‘tallriksmodellen’, som delas med vars sin skål ris i
      handen. Komponera ihop er Family style-middag, vi rekommenderar ca 3-4
      rätter per par.
    </p>

    <div class="menu-category">
      <menu-item
        v-for="(item, index) in this.items"
        :key="index"
        :item="item"
      />
    </div>
  </article>
</template>

<style lang="scss" scoped>
h1 {
  font-size: 2rem;
  font-weight: 700;
}
h1 + h2 {
  font-size: 1rem;
}
</style>

<script>
import MenuItem from "../components/MenuItem.vue";

const locations = {
  malmo: [
    {
      name: {
        sv: "Gurksallad. Krispig gurka, soja, vinäger, chili, sesam",
        zh: "탠뼝믿",
        en: ""
      },
      price: 0,
      quantity: 0
    },
    {
      name: {
        sv: "Krispiga wontons, scampi, Sötsur-chilidipp",
        zh: "炸餛飩",
        en: ""
      },
      price: 0,
      quantity: 0
    },
    {
      name: {
        sv: "Kailan (kinesisk broccoli), Shiitake, judasöra, tofu",
        zh: "時꽉窮",
        en: ""
      },
      price: 0,
      quantity: 0
    },
    {
      name: {
        sv: "Bönor & högrev. Långbönor, högrevsfärs, ostron-fisksås",
        zh: "낫떴큇흄",
        en: ""
      },
      price: 0,
      quantity: 0
    },
    {
      name: {
        sv: "Soja & ingefära-lax, rostad salladslök och ingefära, sötsoja",
        zh: "煎三文鱼",
        en: ""
      },
      price: 0,
      quantity: 0
    },
    {
      name: {
        sv: '"Salt & Pepper Scampi". Råfriterad Scampi, lök, paprika',
        zh: "슘吉瞿",
        en: ""
      },
      price: 0,
      quantity: 0
    },
    {
      name: {
        sv: '"Side meat". En tallrik med Anka / Siu Yuk',
        zh: "燒瓘",
        en: ""
      },
      price: 0,
      quantity: 0
    },
    {
      name: {
        sv:
          'Wo Tip - "Crispy Bottom" Dumplings, sötsoja, kinesisk vinäger, chiliolja',
        zh: "鍋貼 (4/8st)",
        en: ""
      },
      price: 0,
      quantity: 0
    }
  ],
  stockholm: [
    {
      name: {
        sv: "Krispig gurka, soja, vinäger, chili, sesam",
        zh: "拍黄瓜 veg",
        en: ""
      },
      price: 0,
      quantity: 0
    },
    {
      name: {
        sv: "Friterade wontons, scampifärs, sötsyrlig sås",
        zh: "餛飩",
        en: ""
      },
      price: 0,
      quantity: 0
    },
    {
      name: {
        sv: "Dumplings, skinkfärs, kål, ingefära, soja-chilivinäger",
        zh: "鍋貼",
        en: ""
      },
      price: 0,
      quantity: 0
    },
    {
      name: {
        sv: "Ryggbiff, brytbönor, fermenterad svartböna",
        zh: "炒豆牛肉",
        en: ""
      },
      price: 0,
      quantity: 0
    },
    {
      name: {
        sv: "Stekt lax, rostad salladslök och ingefära, sötsoja",
        zh: "煎三文鱼",
        en: ""
      },
      price: 0,
      quantity: 0
    },
    {
      name: {
        sv: "Råfriterade vannameiräkor, lök, paprika, chili-lime",
        zh: "椒盐虾",
        en: ""
      },
      price: 0,
      quantity: 0
    },
    {
      name: {
        sv: "Kantonesisk grillad anka/ Krispigt grillat sidfläsk",
        zh: "燒味",
        en: ""
      },
      price: 0,
      quantity: 0
    },
    {
      name: {
        sv: "Wokade vetenudlar, rostbiff, purjolök och böngroddar",
        zh: "干炒牛河",
        en: ""
      },
      price: 0,
      quantity: 0
    },
    {
      name: {
        sv: "Vegansk Mapo Tofu, silkeslen tofu, sojafärs, tomat, chili",
        zh: "麻婆豆腐 veg",
        en: ""
      },
      price: 0,
      quantity: 0
    },
    {
      name: {
        sv: "Kailan (kinesisk broccoli), vitlök, ostronsås",
        zh: "時菜香 veg",
        en: ""
      },
      price: 0,
      quantity: 0
    }
  ]
};

export default {
  components: { MenuItem },
  data: () => ({
    id: null,
    items: []
  }),
  created() {
    this.id = this.$route.params.id;
    this.items = locations[this.id] ?? [];
  }
};
</script>
