<template>
  <article class="menu">
    <p>
      <router-link :to="`/${$route.params.id}`">Tillbaka</router-link>
    </p>
    <p class="mb-0">
      Vi serverar lunch alla helgfria vardagar 11:30 - 14:30. Menyn består av
      våra grillade signaturer, minst en vegansk rätt och en veckans special.
      Alla rätter går utmärkt att få som Take away.
    </p>
    <p class="accent bold">
      Ring in din beställning
      <a href="tel:040319898">040 &mdash; 31 98 98</a>
    </p>
    <h1>午餐 - Lunch</h1>
    <section class="menu-container">
      <div class="menu-category">
        <h2>午餐 - Veckans speciallunch</h2>
        <p class="body">
          Varje vecka har vi en ny rolig rätt att servera.
          <a href="https://www.instagram.com/koket.lu"
            >Följ oss på Instagram för menyuppdateringar.</a
          >
        </p>
        <p class="body">This week's special lunch.</p>
      </div>
      <menu-category
        v-for="(category, index) in this.menu"
        :key="`category-${index}`"
        :category="category"
      />
    </section>
  </article>
</template>

<script>
import MenuCategory from "../components/MenuCategory.vue";
export default {
  components: { MenuCategory },
  data: () => ({
    menu: [
      {
        name: {
          sv: "Signaturer - Cantonese Roast Meat",
          zh: "燒味"
        },
        items: [
          {
            name: {
              sv: "Grillad anka med ris/äggnudlar",
              zh: "燒鴨飯/蛋麵",
              en: "Roast duck with rice/egg noodles"
            },
            price: 135,
            quantity: 0
          },
          {
            name: {
              sv: "Siu Yuk - Krispigt grillat sidfläsk med ris/äggnudlar",
              zh: "燒肉飯/蛋麵",
              en: "Crispy roasted pork belly with rice/egg noodles"
            },
            price: 130,
            quantity: 0
          }
        ]
      },
      {
        name: {
          sv: "Veganskt",
          zh: "小吃"
        },
        items: [
          {
            name: {
              sv:
                "Sichuankökets favvis i en vegansk version: - Mapo Tofu! Het och kryddig tofu-gryta med sojafärs, Serveras med ris",
              zh: "麻婆豆腐",
              en: "Mapo Tofu in a vegan version"
            },
            price: 130,
            quantity: 0
          },
          {
            name: {
              sv: "En vitlökssprängd grönsakswok med tofu och shiitake svamp",
              zh: "時菜香飯",
              en: "Vegan of the season"
            },
            price: 130,
            quantity: 0
          }
        ]
      }
    ]
  })
};
</script>
