<template>
  <article class="menu">
    <p>
      <router-link :to="`/${$route.params.id}`">Tillbaka</router-link>
    </p>
    <p class="accent bold">
      Ring in din beställning
      <a href="tel:040319898">040 &mdash; 31 98 98</a>
    </p>
    <h1>菜單 - Take away-meny</h1>
    <section class="menu-container">
      <template v-if="this.menu.length === 0">
        <span class="accent">Hämtar meny...</span>
      </template>
      <menu-category
        v-for="(category, index) in this.menu"
        :key="`category-${index}`"
        :category="category"
      />
    </section>
  </article>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";
import MenuCategory from "../components/MenuCategory.vue";

export default {
  components: { MenuCategory },
  data: () => ({
    menu: []
  }),
  methods: {
    getMenu() {
      const menuRef = firebase.database().ref("menu");
      return menuRef.once("value").then(snapshot => {
        const value = Object.values(snapshot.val());
        value.forEach(category => {
          if (category.items) category.items = Object.values(category.items);
        });
        return (this.menu = value
          .filter(c => c.items)
          .sort((a, b) => (a.order > b.order ? 1 : -1)));
      });
    }
  },
  beforeMount() {
    this.getMenu();
  }
};
</script>

<style lang="scss">
.text-vertical {
  writing-mode: vertical-lr;
  text-orientation: upright;
}

.menu {
  flex-direction: column;
  align-items: flex-start;
}

.menu-category {
  margin-bottom: 50px;
  > p:not(.body) {
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-right: 0;
    color: var(--accent-color);
  }

  .menu-item {
    margin-bottom: 30px;
    p {
      &:first-child {
        font-size: 1.1rem;
      }
      &:first-child,
      &:first-child + p {
        margin: 0;
        &:not(:last-child) {
          color: var(--accent-color);
        }
      }
      margin-top: 0;
      padding-left: 0;
    }

    .chinese + .price {
      margin-left: 8px;
    }
  }
}
</style>
