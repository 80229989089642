<template>
  <div class="menu-item">
    <p>
      <span class="chinese">{{ item.name.zh }}</span>
      <template v-if="item.price > 0">
        <span class="price">
          {{ item.price }}.-{{
            item.quantity > 1 ? ` (${item.quantity} st.)` : ""
          }}
        </span>
      </template>
    </p>
    <p>{{ item.name.sv }}</p>
    <p v-if="item.name.en" v-html="item.name.en" />
  </div>
</template>

<script>
export default {
  props: {
    item: {}
  }
};
</script>
